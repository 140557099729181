import React, { useRef } from 'react';
import styled from 'styled-components';
import { Form, Button, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Select from 'react-select';

import ErrorModal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import ToggleSwitch from './ToggleSwitch';

const StyledContainer = styled(Container)`
    background: #ffeac4;
    color: white;
    padding: 4% 25%;

    @media (max-width: 600px) {
        padding: 20px 55px !important;
    }
`;

const StyledH1 = styled.h1`
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
    z-index: 2;
`;

const StyledP = styled.p`
    font-family: 'Open Sans', sans-serif;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    font-weight: 500;
    z-index: 2;
`;

const StyledForm = styled(Form)`
    .form-group-label {
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 14px;
    }
`;

const StyledFormLabel = styled(Form.Label)`
    color: rgba(0, 0, 0, 0.6);
    font-family: Open Sans;
    font-weight: 600;
    font-size: 14px;
`;

const StyledFormLabelContent = styled.p`
    color: black;
    font-family: Open Sans;
    margin-left: 10px;
    font-size: 14px;
    margin-top: -5px;
    font-weight: bold;
    user-select: none;
    z-index: 1000 !important;
`;

const StyledFromGroupContainer = styled(Col)`
    z-index: 4;
    display: flex;
    justify-content: center;
`;

const StyledSubmitButton = styled(Button)`
    background-color: #407dfa;
    max-width: fit-content;
    padding: 5px 40px;
    border-radius: 25px;
    font-family: Open Sans;
    font-weight: 700;
    font-size: 18px;
    color: white;
    text-align: center;
`;

const StyledCol = styled(Col)`
    z-index: 4;
`;

const StyledToggleContainer = styled(Stack)`
    width: fit-content;
    background: #a8c3fb;
    padding: 5px 6px 5px 20px;
    border-radius: 50px;
    color: #6743be;
    font-weight: bold;
    gap: 20px;

    p {
        margin: auto !important;
    }
`;

export default function FormBody({
    handleData,
    data,
    handleSubmit,
    planObject,
    formLoading,
    formRestrictions = {},
    handleReferrerValidate = async () => {},
    address,
    setAddress
}) {
    const [form, setForm] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [errorMessages, setErrorMessages] = React.useState({});
    const previousErrorsRef = React.useRef([]);
    const previousErrorsRef2 = React.useRef([]);
    const [isReferrerCodeDisabled, setIsReferrerCodeDisabled] = React.useState(false);
    const [reloadCount, setReloadCount] = React.useState(parseInt(localStorage.getItem('reloadCount') || '0', 10));

    const formRefs = {
        'FIRST NAME': useRef(),
        'MIDDLE NAME': useRef(),
        'LAST NAME': useRef(),
        'MOBILE NUMBER': useRef(),
        EMAIL: useRef(),
        'BIRTH DATE': useRef(),
        'REFERRER CODE': useRef(),
        GENDER: useRef(),
        TIN: useRef(),
        PROVINCE: useRef(),
        CITY: useRef(),
        BARANGAY: useRef()
    };

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const referrerCode = queryParams.get('code');

        if (reloadCount === 0 && referrerCode) {
            handleData((prevData) => ({
                ...prevData,
                pref: referrerCode
            }));
            setIsReferrerCodeDisabled(true);
            setReloadCount(reloadCount + 1);
            localStorage.setItem('reloadCount', String(reloadCount + 1));
            queryParams.delete('code');
            const newUrl = window.location.pathname + '' + queryParams.toString();
            window.history.replaceState(null, '', newUrl);
        } else {
            localStorage.removeItem('reloadCount');
        }
    }, [reloadCount, handleData]);

    React.useEffect(() => {
        if (previousErrorsRef?.current?.length) {
            const errorField = previousErrorsRef.current[0];
            const fieldType = formRefs[errorField].current.type;
            setTimeout(() => {
                if (fieldType === 'select-one') {
                    formRefs[errorField].current.showPicker();
                } else {
                    formRefs[errorField].current.focus();
                }
            }, 400);
        }

        previousErrorsRef.current = errors.slice() || [];
    }, [errors]);

    React.useEffect(() => {
        return () => {
            setForm(() => null);
            setErrors(() => []);
            setErrorMessages(() => ({}));
        };
    }, []);

    // Access the previous value using previousErrorsRef.current

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        handleData((p) => {
            return {
                ...p,
                [name]:
                    type === 'checkbox'
                        ? checked
                        : name === 'mobileNo'
                        ? value.replace(/[^0-9]/g, '').slice(0, 10) // for mobile number, 10 digits only
                        : name === 'tin'
                        ? value.replace(/[^0-9]/g, '').slice(0, 14) // for TIN, 14 digits only
                        : value
            };
        });
    };

    const handleCheckboxChange = () => {
        handleData((p) => ({ ...p, agreed: !p.agreed }));
    };

    // const handleRecurringCheckboxChange = () => {
    //     handleData((p) => ({ ...p, recurring: !p.recurring }));
    // };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const formattedMobileNo = `+63-${data.mobileNo}`;
            const dataWithPrefix = { ...data, mobileNo: formattedMobileNo };
            await handleValidate(data);
            setForm(() => dataWithPrefix);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleSubmitConfirm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const formattedMobileNo = `+63-${data.mobileNo}`;
            const dataWithPrefix = { ...data, mobileNo: formattedMobileNo };
            handleSubmit(dataWithPrefix);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleValidate = async (_form) => {
        const messages = {};
        const errors = [];
        previousErrorsRef2.current = [];
        const regex = /^(?=.*[a-zA-Z\sñÑ\s.\s-])[a-zA-Z0-9\sñÑ\s.\s-]+$/;
        // const tinRegex =
        // /^(?!0)(?!\d*([1-9])\1{3,})(?!0{8,})(?!0123456789)(?!987654321)(?!123456789)\d{14}|00000000000000$/;
        const tinRegex = /^(?!\d*([1-9])\1{3,})(?!0{8,})(?!0123456789)(?!987654321)(?!123456789)\d{14}|00000000000000$/;

        // Errors
        if (!_form.firstName || !regex.test(_form.firstName || '')) {
            errors.push('FIRST NAME');
            messages['FIRST NAME'] = !_form.firstName ? 'First name is required' : 'First name is invalid';
        }

        if (_form.middleName && !regex.test(_form.middleName || '')) {
            errors.push('MIDDLE NAME');
            messages['MIDDLE NAME'] = 'Middle name is invalid';
        }

        if (!_form.lastName || !regex.test(_form.lastName || '')) {
            errors.push('LAST NAME');
            messages['LAST NAME'] = !_form.lastName ? 'Last name is required' : 'Last name is invalid';
        }

        if (!_form.mobileNo || (_form?.mobileNo?.length ?? '') < 10) {
            errors.push('MOBILE NUMBER');
            messages['MOBILE NUMBER'] = !_form.mobileNo
                ? 'Mobile number is required'
                : 'Mobile number must be 10 digits long';
        }

        if (!_form.dob) {
            errors.push('BIRTH DATE');
            messages['BIRTH DATE'] = 'Birth date is required';
        } else {
            const { dob } = formRestrictions;
            const minDate = new Date(dob?.min);
            const maxDate = new Date(dob?.max);
            const selectedDate = new Date(_form.dob);

            if (selectedDate < minDate || selectedDate > maxDate) {
                errors.push('BIRTH DATE');
                messages['BIRTH DATE'] = `Client must be between ${dob.minInt} and ${dob.maxInt} years old`;
            }
        }

        if (!_form.gender) {
            errors.push('GENDER');
            messages['GENDER'] = 'Gender is required';
        }

        if (!_form.email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(_form.email)) {
            errors.push('EMAIL');
            messages['EMAIL'] = !_form.email ? 'Email is required' : 'Email is invalid';
        }

        if (!!_form.pref) {
            const isValid = await handleReferrerValidate(data.pref);
            if (!isValid) {
                errors.push('REFERRER CODE');
                messages['REFERRER CODE'] = 'Referrer code is invalid';
            }
        }

        if (!_form.tin || !tinRegex.test(_form.tin)) {
            errors.push('TIN');
            // messages['TIN'] = 'TIN is invalid';
            messages['TIN'] = 'TIN must be 14 digits';
        }

        if (!_form.province) {
            errors.push('PROVINCE');
            messages['PROVINCE'] = 'Province is required';
        }

        if (!_form.city) {
            errors.push('CITY');
            messages['CITY'] = 'City is required';
        }
        if (!_form.barangay) {
            errors.push('BARANGAY');
            messages['BARANGAY'] = 'Barangay is required';
        }

        if (errors.length) {
            previousErrorsRef2.current = errors.slice();
            setErrors(() => errors);
            setErrorMessages(() => ({ ...messages }));
            throw new Error('Error on validation', errors);
        }

        return;
    };

    const handleAutoRenew = () => {
        handleData((p) => ({ ...p, autoRenew: !p.autoRenew, recurring: false }));
    };

    const handleRecurringCheckboxChange = () => {
        handleData((p) => ({ ...p, recurring: !p.recurring }));
    };

    const setGender = (event) => {
        handleData((p) => ({ ...p, gender: event.target.value }));
        const index = previousErrorsRef2.current.indexOf('GENDER');
        if (index !== -1) {
            const _newArr = previousErrorsRef2.current.slice();
            _newArr.splice(index, 1);
            previousErrorsRef2.current = _newArr;
        }
    };

    const filterCity = (province) => {
        const matchedCity = address.city.filter((item) => item.provinceCd === province.provinceCd);

        handleInputChange({ target: { name: 'province', value: province.provinceName } });
        handleInputChange({ target: { name: 'city', value: null } });
        handleInputChange({ target: { name: 'barangay', value: null } });
        handleInputChange({ target: { name: 'zipCode', value: '' } });

        setAddress({
            ...address,
            matchedCity: matchedCity,
            matchedBarangay: null
        });
    };

    const filterBarangay = (city) => {
        const matchedBarangay = address.barangay.filter(
            (item) => item.provinceCd === city.provinceCd && item.cityCd === city.cityCd
        );

        handleInputChange({ target: { name: 'city', value: city.cityName } });
        handleInputChange({ target: { name: 'barangay', value: null } });
        handleInputChange({ target: { name: 'zipCode', value: '' } });

        setAddress({
            ...address,
            matchedBarangay: matchedBarangay
        });
    };

    return (
        <StyledContainer fluid id="form-section">
            <Row>
                <StyledH1>Get covered today.</StyledH1>
                <StyledP>Fill-out the form below and activate your Personal Accident insurance in minutes.</StyledP>
            </Row>
            <StyledForm>
                <Row>
                    <StyledCol lg={4}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                FIRST NAME<span>*</span>
                            </StyledFormLabel>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={data.firstName}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['FIRST NAME']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('FIRST NAME')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('FIRST NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">MIDDLE NAME</StyledFormLabel>
                            <Form.Control
                                type="text"
                                name="middleName"
                                value={data.middleName}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('MIDDLE NAME')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onChange={handleInputChange}
                                ref={formRefs['MIDDLE NAME']}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('MIDDLE NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                LAST NAME<span>*</span>
                            </StyledFormLabel>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={data.lastName}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['LAST NAME']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('LAST NAME') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('LAST NAME');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol lg={4}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                BIRTH DATE<span>*</span>
                            </StyledFormLabel>
                            <Form.Control
                                type="date"
                                name="dob"
                                value={data.dob ?? null}
                                onChange={handleInputChange}
                                required
                                ref={formRefs['BIRTH DATE']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('BIRTH DATE')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('BIRTH DATE');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4}>
                        <StyledFormLabel className="form-group-label">
                            GENDER
                            <span>*</span>
                        </StyledFormLabel>
                        <Form.Select
                            size="sm"
                            name="gender"
                            ref={formRefs['GENDER']}
                            required
                            style={{
                                textTransform: 'uppercase',
                                borderColor: (previousErrorsRef2?.current ?? []).includes('GENDER') ? 'red' : ''
                            }}
                            onChange={setGender}
                        >
                            <option value="" disabled selected></option>
                            {[
                                { name: 'MALE', value: 'm' },
                                { name: 'FEMALE', value: 'f' }
                            ].map((type, index) => {
                                return (
                                    <option
                                        value={type?.value}
                                        {...(type?.value === data.gender && { selected: true })}
                                        key={`type-${index}`}
                                    >
                                        {type?.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </StyledCol>

                    <StyledCol lg={4}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                MOBILE NUMBER<span>*</span>
                            </StyledFormLabel>
                            <div className="input-group">
                                <span
                                    className="input-group-text custom-input"
                                    style={{
                                        borderColor: (previousErrorsRef2?.current ?? []).includes('MOBILE NUMBER')
                                            ? 'red'
                                            : '',
                                        textTransform: 'uppercase' ? 'red' : ''
                                    }}
                                >
                                    +63
                                </span>
                                <Form.Control
                                    className="custom-input"
                                    type="tel"
                                    name="mobileNo"
                                    value={data.mobileNo}
                                    onChange={handleInputChange}
                                    pattern="[0-9]*"
                                    maxLength="10"
                                    required
                                    ref={formRefs['MOBILE NUMBER']}
                                    style={{
                                        borderColor: (previousErrorsRef2?.current ?? []).includes('MOBILE NUMBER')
                                            ? 'red'
                                            : '',
                                        textTransform: 'uppercase' ? 'red' : ''
                                    }}
                                    onFocus={() => {
                                        const index = previousErrorsRef2.current.indexOf('MOBILE NUMBER');
                                        if (index !== -1) {
                                            const _newArr = previousErrorsRef2.current.slice();
                                            _newArr.splice(index, 1);
                                            previousErrorsRef2.current = _newArr;
                                        }
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                EMAIL<span>*</span>
                            </StyledFormLabel>
                            <Form.Control
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={handleInputChange}
                                required
                                ref={formRefs.EMAIL}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('EMAIL') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('EMAIL');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                TAXPAYER IDENTIFICATION NO.<span>*</span>
                            </StyledFormLabel>
                            <Form.Control
                                type="text"
                                name="tin"
                                value={data.tin}
                                onChange={handleInputChange}
                                required
                                ref={formRefs.TIN}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('TIN') ? 'red' : '',
                                    textTransform: 'uppercase'
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end', zIndex: '1000' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                PROVINCE<span>*</span>
                            </StyledFormLabel>
                            <Select
                                name="province"
                                id="province"
                                className="custom-select"
                                classNamePrefix="custom-select"
                                options={address?.province}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={address?.province?.find(
                                    (option) => option.value?.toLowerCase() === data.province?.toLowerCase()
                                )}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: '31px',
                                        minHeight: '31px',
                                        boxShadow: 'none',
                                        padding: '0',
                                        alignItems: 'center',
                                        textTransform: 'uppercase'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                        marginBottom: '8px'
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        height: '31px',
                                        margin: '0'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        textTransform: 'uppercase'
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '4px', // Adjust padding if needed
                                        marginBottom: '8px;'
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        marginBottom: '20px;'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    filterCity(selectedOption);
                                }}
                                ref={formRefs['PROVINCE']}
                            />
                        </Form.Group>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end', zIndex: '999' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                CITY / MUNICIPALITY<span>*</span>
                            </StyledFormLabel>
                            <Select
                                name="city"
                                id="city"
                                className={address?.matchedCity?.length ? '' : 'custom-readonly'}
                                options={address?.matchedCity}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={
                                    address?.matchedCity?.find(
                                        (option) => option.value?.toLowerCase() === data.city?.toLowerCase()
                                    ) || null
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: '31px',
                                        minHeight: '31px',
                                        boxShadow: 'none',
                                        padding: '0',
                                        alignItems: 'center',
                                        textTransform: 'uppercase'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                        marginBottom: '8px'
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        height: '31px',
                                        margin: '0'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        textTransform: 'uppercase'
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '4px', // Adjust padding if needed
                                        marginBottom: '8px;'
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        marginBottom: '20px;'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    filterBarangay(selectedOption);
                                }}
                                ref={formRefs['CITY']}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end', zIndex: '998' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                BARANGAY<span>*</span>
                            </StyledFormLabel>
                            <Select
                                name="barangay"
                                id="barangay"
                                className={address?.matchedBarangay?.length ? '' : 'custom-readonly'}
                                options={address?.matchedBarangay}
                                getOptionLabel={(option) => option.value}
                                getOptionValue={(option) => option.key}
                                value={
                                    address?.matchedBarangay?.find(
                                        (option) => option.value?.toLowerCase() === data.barangay?.toLowerCase()
                                    ) || null
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        height: '31px',
                                        minHeight: '31px',
                                        boxShadow: 'none',
                                        padding: '0',
                                        alignItems: 'center',
                                        textTransform: 'uppercase'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0 8px',
                                        marginBottom: '8px'
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        height: '31px',
                                        margin: '0'
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                        textTransform: 'uppercase'
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: '4px', // Adjust padding if needed
                                        marginBottom: '8px;'
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        marginBottom: '20px;'
                                    })
                                }}
                                onChange={(selectedOption) => {
                                    handleInputChange({ target: { name: 'barangay', value: selectedOption.value } });
                                    handleInputChange({ target: { name: 'zipCode', value: selectedOption.bmCode } });
                                }}
                                ref={formRefs['BARANGAY']}
                            />
                        </Form.Group>
                    </StyledCol>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end', zIndex: '4' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">
                                ZIP CODE<span>*</span>
                            </StyledFormLabel>
                            <Form.Control
                                type="text"
                                id="zipCode"
                                className={`form-group-input-custom custom-readonly`}
                                name="lastName"
                                readOnly
                                value={data.zipCode}
                                required
                            />
                        </Form.Group>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol lg={4} style={{ alignContent: 'flex-end', zIndex: '1' }}>
                        <Form.Group className="mb-3">
                            <StyledFormLabel className="form-group-label">REFERRER CODE</StyledFormLabel>
                            <Form.Control
                                type="text"
                                name="pref"
                                value={data.pref}
                                onChange={handleInputChange}
                                disabled={isReferrerCodeDisabled}
                                required
                                ref={formRefs['REFERRER CODE']}
                                style={{
                                    borderColor: (previousErrorsRef2?.current ?? []).includes('REFERRER CODE')
                                        ? 'red'
                                        : '',
                                    textTransform: 'uppercase'
                                }}
                                onFocus={() => {
                                    const index = previousErrorsRef2.current.indexOf('REFERRER CODE');
                                    if (index !== -1) {
                                        const _newArr = previousErrorsRef2.current.slice();
                                        _newArr.splice(index, 1);
                                        previousErrorsRef2.current = _newArr;
                                    }
                                }}
                            />
                        </Form.Group>
                    </StyledCol>
                </Row>
                <Row className="mb-3" style={{ padding: '0 3px' }}>
                    <StyledFromGroupContainer xs={12} style={{ justifyContent: 'center' }}>
                        <StyledToggleContainer direction="horizontal">
                            <p>RENEW ANNUALLY</p>
                            <ToggleSwitch value={data.autoRenew} onChange={handleAutoRenew} />
                        </StyledToggleContainer>
                    </StyledFromGroupContainer>
                </Row>

                <Row style={{ padding: '0 3px' }}>
                    <StyledFromGroupContainer xs={12}>
                        <div>
                            <Form.Group controlId="formCheckbox">
                                <Form.Check
                                    className="checkbox"
                                    type="checkbox"
                                    onChange={handleCheckboxChange}
                                    checked={data.agreed}
                                    name="agreed"
                                    label={
                                        <StyledFormLabelContent>
                                            I have read, understood and agree to the{' '}
                                            <a
                                                href="https://bpims.com/wp-content/uploads/2023/10/Terms-of-Use.pdf"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                Terms of Use
                                            </a>{' '}
                                            of this microsite, the{' '}
                                            <a
                                                href="https://bpims.com/privacy-policy/"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                BPI MS Privacy Policy
                                            </a>{' '}
                                            the{' '}
                                            <a
                                                href="https://bpims.com/wp-content/uploads/2023/10/BPIMS_Stakeholder_Charter.pdf"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                BPI MS Customer Charter
                                            </a>{' '}
                                            and the{' '}
                                            <a
                                                href="https://bpims.com/wp-content/uploads/2023/09/Insuring-TCs-PA-Individual-PA365.pdf"
                                                target="bpims"
                                                style={{ color: 'black' }}
                                            >
                                                Terms and Conditions of this Policy
                                            </a>
                                            {','} issued and underwritten by BPI/MS Insurance Corporation.
                                        </StyledFormLabelContent>
                                    }
                                />
                            </Form.Group>
                        </div>
                    </StyledFromGroupContainer>
                </Row>

                {data.autoRenew && (
                    <Row style={{ padding: '0 3px' }}>
                        <StyledFromGroupContainer>
                            <div>
                                <Form.Group controlId="recurringCheckbox">
                                    <Form.Check
                                        className="checkbox"
                                        type="checkbox"
                                        onChange={handleRecurringCheckboxChange}
                                        checked={data.recurring}
                                        name="recurring"
                                        label={
                                            <StyledFormLabelContent>
                                                I confirm and allow BPI MS to automatically renew my Personal Accident
                                                Insurance yearly upon its expiry and automatically charge my Debit card
                                                or Credit card yearly for the corresponding annual insurance premium. I
                                                understand and agree that if I want to cancel the yearly automatic
                                                charging on my Debit Card or Credit Card and automatic renewal of my
                                                Personal Accident Insurance coverage, I will need to contact BPI MS at{' '}
                                                <a href="tel:+63288409000" style={{ color: 'black' }}>
                                                    (02) 8840-9000
                                                </a>{' '}
                                                or send an email at{' '}
                                                <a href="mailto:bpimshelpline@bpi.com.ph" style={{ color: 'black' }}>
                                                    bpimshelpline@bpi.com.ph
                                                </a>{' '}
                                                prior to the expiry date of my Personal Accident Insurance coverage.
                                            </StyledFormLabelContent>
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </StyledFromGroupContainer>
                    </Row>
                )}

                <div className="d-grid gap-2 justify-content-center mt-2">
                    <StyledSubmitButton
                        className="btn-submit"
                        variant="primary"
                        type="button"
                        disabled={formLoading || !data.agreed || (data.autoRenew && !data.recurring) || !planObject.id}
                        onClick={handleFormSubmit}
                    >
                        PAY NOW
                    </StyledSubmitButton>
                </div>
            </StyledForm>
            <ErrorModal
                show={errors.length}
                handleClose={() => {
                    setErrors(() => []);
                    setErrorMessages(() => ({}));
                }}
                errorMessage={errorMessages}
            />
            <ConfirmationModal
                show={form}
                handleClose={() => setForm()}
                plan={planObject}
                handleSubmit={handleSubmitConfirm}
            />
        </StyledContainer>
    );
}
